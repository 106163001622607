import React from "react";
import WidgetCategory from "./WidgetCategory";
import WidgetTag from "./WidgetTag";
import WidgetLatestPosts from "./WidgetLatestPosts";
import WidgetLinks from "./WidgetLinks";
import WidgetSearch from "./WidgetSearch";
import config from "../../../data/SiteConfig";
import AdSense from "react-adsense";
import "./Sidebar.scss";

const Sidebar = ({
  tagList,
  categoryList,
  latestPostEdges,
  links,
  hasAdsenseTop = false,
  hasAdsenseMiddle = false,
  hasAdsenseBottomLeft = false,
  hasAdsenseBottomRight = false,
  hasSearch = true,
}) => {
  return (
    <aside
      className={`sidebar-container width-full ${
        config.sidebarSticky ? "height-full" : ""
      }`}
    >
      {hasAdsenseTop && config.hasAdsense && config.adsenseUnits.sidebarTop && (
        <div className="width-full flex justify-content-center padding-top">
          <AdSense.Google {...config.adsenseUnits.sidebarTop} />
        </div>
      )}

      {hasSearch && <WidgetSearch />}

      {latestPostEdges && (
        <WidgetLatestPosts latestPostEdges={latestPostEdges} />
      )}

      {hasAdsenseMiddle &&
        latestPostEdges &&
        config.hasAdsense &&
        config.adsenseUnits.sidebarMiddle && (
          <div className="width-full flex justify-content-center padding-top">
            <AdSense.Google {...config.adsenseUnits.sidebarMiddle} />
          </div>
        )}

      {categoryList && <WidgetCategory categoryList={categoryList} />}

      {tagList && <WidgetTag tagList={tagList} />}

      {links && <WidgetLinks links={links} />}

      {hasAdsenseBottomLeft &&
        config.hasAdsense &&
        config.adsenseUnits.sidebarBottomLeft && (
          <div className="position-sticky last-item padding-top padding-bottom">
            <AdSense.Google {...config.adsenseUnits.sidebarBottomLeft} />
          </div>
        )}

      {hasAdsenseBottomRight &&
        config.hasAdsense &&
        config.adsenseUnits.sidebarBottomRight && (
          <div className="position-sticky last-item padding-top padding-bottom">
            <AdSense.Google {...config.adsenseUnits.sidebarBottomRight} />
          </div>
        )}
    </aside>
  );
};

export default Sidebar;
