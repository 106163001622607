import React from "react";
import "./MainContainer.scss";

const MainContainer = ({
  content,
  sidebarLeft,
  sidebarRight,
  className = "",
}) => (
  <main className={`main-container container ${className}`}>
    {sidebarLeft && (
      <div className="sidebar-wrapper left padding-top-half padding-bottom-2">
        {sidebarLeft}
      </div>
    )}
    {content && (
      <div
        className={`content-wrapper padding-top-half padding-bottom-2 ${
          sidebarLeft ? "hasLeft" : ""
        } ${sidebarRight ? "hasRight" : ""}`}
      >
        {content}
      </div>
    )}
    {sidebarRight && (
      <div className="sidebar-wrapper right padding-top-half padding-bottom-2">
        {sidebarRight}
      </div>
    )}
  </main>
);

export default MainContainer;
